@tailwind base;
@tailwind components;
@tailwind utilities;
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&family=Kanit:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap");

.color {
  color: #d748ff;
  color: #48b3ff;
}

.ant-select-selector:where(.css-dev-only-do-not-override-gzal6t).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
}
